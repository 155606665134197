@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Thin.ad538a69.woff2) format('woff2'), url(/static/media/Roboto-Thin.d3b47375.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url(/static/media/Roboto-Thin.ad538a69.woff2) format('woff2'), url(/static/media/Roboto-Thin.d3b47375.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-ThinItalic.5b4a33e1.woff2) format('woff2'), url(/static/media/Roboto-ThinItalic.8a96edbb.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url(/static/media/Roboto-ThinItalic.5b4a33e1.woff2) format('woff2'), url(/static/media/Roboto-ThinItalic.8a96edbb.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Light.d26871e8.woff2) format('woff2'), url(/static/media/Roboto-Light.c73eb1ce.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(/static/media/Roboto-Light.d26871e8.woff2) format('woff2'), url(/static/media/Roboto-Light.c73eb1ce.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-LightItalic.e8eaae90.woff2) format('woff2'), url(/static/media/Roboto-LightItalic.13efe6cb.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url(/static/media/Roboto-LightItalic.e8eaae90.woff2) format('woff2'), url(/static/media/Roboto-LightItalic.13efe6cb.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Regular.73f0a88b.woff2) format('woff2'), url(/static/media/Roboto-Regular.35b07eb2.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(/static/media/Roboto-Regular.73f0a88b.woff2) format('woff2'), url(/static/media/Roboto-Regular.35b07eb2.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-RegularItalic.4357beb8.woff2) format('woff2'), url(/static/media/Roboto-RegularItalic.f5902d5e.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-RegularItalic';
    src: url(/static/media/Roboto-RegularItalic.4357beb8.woff2) format('woff2'), url(/static/media/Roboto-RegularItalic.f5902d5e.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Medium.90d16760.woff2) format('woff2'), url(/static/media/Roboto-Medium.1d659482.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(/static/media/Roboto-Medium.90d16760.woff2) format('woff2'), url(/static/media/Roboto-Medium.1d659482.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-MediumItalic.13ec0eb5.woff2) format('woff2'), url(/static/media/Roboto-MediumItalic.83e114c3.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url(/static/media/Roboto-MediumItalic.13ec0eb5.woff2) format('woff2'), url(/static/media/Roboto-MediumItalic.83e114c3.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Bold.b52fac2b.woff2) format('woff2'), url(/static/media/Roboto-Bold.50d75e48.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(/static/media/Roboto-Bold.b52fac2b.woff2) format('woff2'), url(/static/media/Roboto-Bold.50d75e48.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BoldItalic.94008e69.woff2) format('woff2'), url(/static/media/Roboto-BoldItalic.4fe0f73c.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url(/static/media/Roboto-BoldItalic.94008e69.woff2) format('woff2'), url(/static/media/Roboto-BoldItalic.4fe0f73c.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Black.59eb3601.woff2) format('woff2'), url(/static/media/Roboto-Black.313a6563.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url(/static/media/Roboto-Black.59eb3601.woff2) format('woff2'), url(/static/media/Roboto-Black.313a6563.woff) format('woff');
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BlackItalic.f75569f8.woff2) format('woff2'), url(/static/media/Roboto-BlackItalic.cc2fadc3.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url(/static/media/Roboto-BlackItalic.f75569f8.woff2) format('woff2'), url(/static/media/Roboto-BlackItalic.cc2fadc3.woff) format('woff');
}

